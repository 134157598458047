module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-silverstripe","short_name":"starter","start_url":"/","background_color":"#005b94","theme_color":"#005b94","display":"minimal-ui","icon":"src/images/ss-logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"8","matomoUrl":"https://logs.netwerkstatt.at/","siteUrl":"https://www.astridwildner.at"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
