// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-layout-bio-page-js": () => import("./../src/templates/Layout/BioPage.js" /* webpackChunkName: "component---src-templates-layout-bio-page-js" */),
  "component---src-templates-layout-home-page-js": () => import("./../src/templates/Layout/HomePage.js" /* webpackChunkName: "component---src-templates-layout-home-page-js" */),
  "component---src-templates-layout-image-gallery-page-js": () => import("./../src/templates/Layout/ImageGalleryPage.js" /* webpackChunkName: "component---src-templates-layout-image-gallery-page-js" */),
  "component---src-templates-layout-projekt-holder-page-js": () => import("./../src/templates/Layout/ProjektHolderPage.js" /* webpackChunkName: "component---src-templates-layout-projekt-holder-page-js" */),
  "component---src-templates-layout-blog-js": () => import("./../src/templates/Layout/Blog.js" /* webpackChunkName: "component---src-templates-layout-blog-js" */),
  "component---src-templates-layout-page-js": () => import("./../src/templates/Layout/Page.js" /* webpackChunkName: "component---src-templates-layout-page-js" */),
  "component---src-templates-layout-blog-post-js": () => import("./../src/templates/Layout/BlogPost.js" /* webpackChunkName: "component---src-templates-layout-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

